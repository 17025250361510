.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #11466b;
  border-color: #11466b;
  color: #fff;
}

.pagination > li > a {
  border: 1px solid #11466b;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #11466b;
  border-color: #11466b;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #11466b;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.test-prev {
  margin-right: 1rem;
}

.test-next {
  margin-left: 1rem;
}

.test-break {
  margin: 0 0.5rem;
}
