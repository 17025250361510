@import '~global.scss';

.wrapper {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding: 10vh 5vw;
  box-sizing: border-box;
  color: $grey;

  * {
    box-sizing: border-box;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .reportContainer {
    width: 100%;
    position: relative;
    margin: 10rem 0;
    border-radius: 0.5rem;
    border: 1px solid $blue;
    background: $whiteblue;
    padding: 2rem 5rem;
    overflow-x: scroll;

    h2 {
      text-align: center;
      color: $blue;
    }

    .reset {
      @include button();
      border: 1px solid $orange;
      color: orange;
      display: inline-block;
      padding: 0.5rem 1rem;

      &:hover {
        background: $orange;
        color: white;
      }
    }

    @mixin cta() {
      @include button();
      background: $orange;
      color: white;
      text-align: center;
      font-size: 1.2rem;
      padding: 1.2rem 2rem;
    }

    .fileCard {
      @include flexColumn();
      margin-right: 5rem;

      img {
        width: 4rem;
        margin-bottom: 0.2rem;
      }
    }

    .generateButton {
      @include cta();
    }

    .uploadButton {
      @include cta();
      margin-left: auto;
    }

    .uploadInput {
      visibility: hidden;
    }

    .spinnerContainer {
      margin-top: 5rem;
    }

    .excludedQuestions {
      font-weight: 600;
      margin-top: 5rem;
    }

    .downloadRow {
      display: flex;
      align-items: center;
      margin-left: auto;

      .fileCard {
        margin-right: 1rem;
      }

      .downloadButton {
        @include cta();
        margin-left: auto;
      }
    }

    .resultsContainer {
      overflow-x: scroll;

      canvas {
        transform: scale(0.8);
        transform-origin: left;
      }
    }
  }
}
