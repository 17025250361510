@import '~global.scss';

.save {
  @include button();
  background: $orange;
  width: 10rem;
  height: 2rem;
  color: white;
}

.chartWrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 80rem;

  .chartContainer {
    position: relative;

    .background {
      position: absolute;
      width: 80%;
      left: 20%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .percentLine {
        position: relative;
        height: 100%;
        border-right: 1px solid rgba(200, 200, 200, 0.5);

        &:first-child {
          border-color: black;
        }

        span {
          position: absolute;
          bottom: -1.6rem;
          left: -0.4rem;
        }
      }
    }

    .row {
      position: relative;
      width: 100%;
      margin: 0.2rem 0;
      display: flex;
      align-items: center;
      height: 4rem;

      .label {
        position: absolute;
        display: inline-block;
        width: 15%;
        left: 5%;
      }

      .bar {
        position: relative;
        left: 20%;
        background: $lightorange;
        border-radius: 0.2rem 0.2rem 0 0;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1rem;

        .class {
          position: absolute;
          white-space: nowrap;
          color: white;

          &.shift {
            color: $grey;
            right: -7.5rem;
          }
        }
        .percent {
          position: absolute;
          right: -2.5rem;

          &.shift {
            color: $grey;
            right: -10.3rem;
          }
        }
      }
    }
  }
}
