$darkorange: rgba(209, 133, 101, 1);
$orange: rgba(236, 140, 84, 1);
$lightorange: rgba(245, 197, 169, 1);
$verylightorange: rgba(251, 232, 221, 1);
$superlightorange: rgba(253, 243, 237, 1);
$whiteorange: rgba(253, 253, 247, 1);

$blue: rgba(17, 70, 107, 1);
$verylightblue: rgba(207, 218, 225, 1);
$superlightblue: rgba(231, 236, 240, 1);
$whiteblue: rgba(243, 246, 247, 1);

$grey: rgba(105, 102, 100, 1);
$orangegrey: rgba(190, 176, 167, 1);

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexColumn() {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@mixin button() {
  @include flexCenter();
  border-radius: 0.5rem;
  cursor: pointer;
}
